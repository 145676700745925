export enum LogType {
    UNKNOWN,
    DEVICE_STATUS,
    HEARTBEAT,
    TRANSPORT,
    CLASSIFIER,
    NETWORK,
    INFERENCE
}

export interface LogInfo {
    timestamp:  number
}

export interface PythonInfo extends LogInfo {
    asctime?:   string
    name?:      string
    message?:   string
}

export interface StorageInfo {
    Filesystem?: string
    Size?:      string
    Used?:      number
    Avail?:     string
    "Use%"?:    string
    Mounted?:   string
}

export interface MemInfo {
    size:  number
    type:  string
}

export interface CPUInfo {
    user:      number
    nice:      number
    system:    number
    idle:      number
    iowait:    number
    irq:       number
    softirq:   number
    cpu?:      number
}

export interface TemperatureInfo  {
    [key:string]:  { [field:string]: number | string | TemperatureInfo}
}

//"acpitz_acpi_0": { "Adapter": "ACPI_interface", "temp1": { "temp1_input": 45, "temp1_crit": 119 } },

export interface DeviceStatusInfo extends PythonInfo {
    endo?: boolean
    streaming?: boolean
    hdmi0?: string 
    hdmi1?: string 
    eth0?: string 
    eth1?: string 
    wifi?: string 
    ariane?: boolean
    vpn?: string
    proxy?: string
    temps?: TemperatureInfo
    memory?: {
        MemTotal: MemInfo
        MemFree: MemInfo
        MemAvailable: MemInfo
        USAGE: {
            used: number
            free: number
            available: number
        }
    }, 
    storage?: StorageInfo[]
    processor?: {
        cpu?: CPUInfo, 
        cpus?: CPUInfo[]
        stats?: {
            context_switches?:  number 
            boot_time?:         number 
            processes?:         number 
            running?:           number 
            blocked?:           number 
        }
    }    
}

export interface GoInfo extends LogInfo {
    level?: string
    ts?: number
    caller?: string
    msg?: string
}

export interface ClassifierInfo extends GoInfo {
    frames?: number
}

export interface TransportInfo extends GoInfo {
    TotalTx:        number
    TotalRx:        number
    BytesTx:        number
    BytesRx:        number
    Pending:        number
    PendingBytes:   number
    Capacity:       number    
}
export interface InferenceInfo extends GoInfo {
    meta?: {
        frame_no: number
        frame_time: number
        in_score: number
        no_feed: number
        out_score: number
        ultra_in: number
        ultra_out: number    
    }
}    

export interface Log {
    timestamp: number
    message: string
    object: object
    type: LogType
}

export interface NetworkDevice {
    status?:    string
    ethernet?:  string[]
    ip4?:       string[]
    inet4?:     string[]
    route4?:    string[]
    inet6?:     string[]
    route6?:    string[]
}

export interface NetworkInfo extends PythonInfo {
    "DNS configuration"?: object,
    devices?: { [key:string]: object }
}

export interface CPUSummary {
    idle: number
    usage: number

    raw: {
        idle: number
        usage: number
        total: number
    }
}

export class ProcessorTools {
    static get_epoch_date (seconds: number) : Date {
        const epoch_date = new Date(0)
        epoch_date.setUTCSeconds(seconds)
        return epoch_date
    }

    static get_summary (cpu : CPUInfo) : CPUSummary {
        const summary : CPUSummary = {
            idle: 0,
            usage: 0,
            raw: {
                idle: cpu.idle,
                usage: 0,
                total: (cpu.user + 
                        cpu.system + 
                        cpu.nice + 
                        cpu.iowait + 
                        cpu.idle),
            }
        }

        if (summary.raw.total === 0) {
            return summary
        }
        
        summary.usage = Math.round(((summary.raw.total - summary.raw.idle) / summary.raw.total) * 10000) / 100
        summary.idle = Math.round((summary.raw.idle / summary.raw.total) * 10000) / 100

        return summary
    }
}

export interface DataDogLogInfo {
    data: {
        buckets: {
            by: {
                status: string
            },
            computes: {[key: string] : number }
            }[]
    }
}

export interface DataDogLogSeries {
    data: {
        buckets: {
            by: {
                status: string;
            };
            computes: { [key: string]: { time: string; value: number }[] };
        }[];
    };
    meta: {
        elapsed: number;
        page: {
            after: string;
        };
        request_id: string;
        status: string;
    };
}

export default LogType
